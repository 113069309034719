import React from "react";
import { useParams } from "react-router-dom";
import HTMLViewer from "../../components/HTMLViewer";
import { Empty } from "antd";

const SharedWidget = () => {
  const { id } = useParams();

  if (!id) {
    return <Empty />;
  }

  return <>{id && <HTMLViewer widgetId={id} />}</>;
};

export default SharedWidget;
