import axios from "./axios";

export const getWidgets = async () => {
  try {
    const response = await axios.get("/widgets");
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createWidget = async (name) => {
  try {
    const response = await axios.post("/widgets", { name });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getWidget = async (id) => {
  try {
    const response = await axios.get(`/widgets/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateWidget = async (id, params) => {
  try {
    const response = await axios.patch(`/widgets/${id}`, { ...params });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteWidget = async (id) => {
  try {
    const response = await axios.delete(`/widgets/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const setWidgetAgents = async (id, agents) => {
  try {
    const response = await axios.put(`/widgets/${id}/agents`, agents);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getAgents = async () => {
  try {
    const response = await axios.get("/agents");
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
