import { ReactComponent as HTMLIcon } from "../assets/icons/html.svg";
import { ReactComponent as CSSIcon } from "../assets/icons/css.svg";
import { ReactComponent as IMGIcon } from "../assets/icons/img.svg";
import { ReactComponent as JSIcon } from "../assets/icons/js.svg";
import { ReactComponent as TXTIcon } from "../assets/icons/txt.svg";
import { ReactComponent as XML } from "../assets/icons/xml.svg";
import { ReactComponent as MDIcon } from "../assets/icons/md.svg";

import styled from "styled-components";
export const handleCopyToClipboard = (text) => {
  // Create a textarea element to assist with copying to clipboard
  const textarea = document.createElement("textarea");
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();

  // Use the clipboard API to copy the selected text
  try {
    document.execCommand("copy");
  } catch (error) {}

  // Clean up: remove the temporary textarea
  document.body.removeChild(textarea);
  return true;
};

export function convertToTreeData(data) {
  const treeData = [];

  // Function to recursively process folders and files
  function processItems(items, parentId) {
    return items.map((item) => {
      const node = {
        title: item?.extension ? `${item.name}.${item.extension}` : item.name,
        key: item.id,
      };
      if (item.extension) {
        node.icon = getIconByFileExtension(item.extension);
      }
      if (item.type === "folder") {
        if (item.folders && item.folders.length > 0) {
          node.children = processItems(item.folders, item.id);
        }
        if (item.files && item.files.length > 0) {
          if (!node.children) {
            node.children = [];
          }
          node.children.push(...processItems(item.files, item.id));
        }
      } else {
        node.isLeaf = true;
      }
      return node;
    });
  }

  // Process folders
  if (data.folders && data.folders.length > 0) {
    treeData.push(...processItems(data.folders, null));
  }

  // Process files
  if (data.files && data.files.length > 0) {
    treeData.push(...processItems(data.files, null));
  }

  return treeData;
}

export function getFileType(fileExtension) {
  switch (fileExtension.toLowerCase()) {
    case "abap":
      return "sql";
    case "actionscript":
    case "as":
      return "actionscript";
    case "ada":
      return "ada";
    case "arduino":
      return "arduino";
    case "autoit":
      return "autoit";
    case "bash":
      return "bash";
    case "c":
      return "cpp";
    case "clojure":
      return "clojure";
    case "cs":
      return "cs";
    case "c++":
    case "cpp":
      return "cpp";
    case "coffeescript":
    case "coffee":
      return "coffeescript";
    case "csharp":
    case "c#":
      return "cs";
    case "css":
      return "css";
    case "cuda":
      return "cpp";
    case "d":
      return "d";
    case "dart":
      return "dart";
    case "delphi":
      return "delphi";
    case "elixir":
      return "elixir";
    case "elm":
      return "elm";
    case "erlang":
      return "erlang";
    case "fortran":
      return "fortran";
    case "foxpro":
      return "purebasic";
    case "fsharp":
      return "fsharp";
    case "go":
      return "go";
    case "graphql":
    case "gql":
      return "graphql";
    case "groovy":
      return "groovy";
    case "haskell":
      return "haskell";
    case "haxe":
      return "haxe";
    case "html":
      return "xml";
    case "java":
      return "java";
    case "javascript":
    case "js":
      return "javascript";
    case "json":
      return "json";
    case "julia":
      return "julia";
    case "jsx":
      return "jsx";
    case "kotlin":
      return "kotlin";
    case "latex":
      return "tex";
    case "lisp":
      return "lisp";
    case "livescript":
      return "livescript";
    case "lua":
      return "lua";
    case "markup":
      return "markup";
    case "mathematica":
      return "mathematica";
    case "makefile":
      return "makefile";
    case "matlab":
    case "octave":
      return "matlab";
    case "objectivec":
      return "objectivec";
    case "objective-c":
    case "objective-j":
      return "objectivec";
    case "objectpascal":
      return "delphi";
    case "ocaml":
      return "ocaml";
    case "perl":
      return "perl";
    case "php":
      return "php";
    case "powershell":
      return "powershell";
    case "prolog":
      return "prolog";
    case "puppet":
      return "puppet";
    case "python":
      return "python";
    case "qml":
      return "qml";
    case "r":
      return "r";
    case "racket":
      return "lisp";
    case "restructuredtext":
      return "rest";
    case "rest":
      return "rest";
    case "ruby":
      return "ruby";
    case "rust":
      return "rust";
    case "sass":
      return "less";
    case "less":
      return "less";
    case "scala":
      return "scala";
    case "scheme":
      return "scheme";
    case "shell":
      return "shell";
    case "smalltalk":
      return "smalltalk";
    case "sql":
      return "sql";
    case "standardml":
      return "sml";
    case "sml":
      return "sml";
    case "swift":
      return "swift";
    case "tcl":
      return "tcl";
    case "tex":
      return "tex";
    case "text":
      return "text";
    case "tsx":
      return "tsx";
    case "ts":
    case "typescript":
      return "typescript";
    case "vala":
      return "vala";
    case "vbnet":
      return "vbnet";
    case "verilog":
      return "verilog";
    case "vhdl":
      return "vhdl";
    case "xml":
      return "xml";
    case "xquery":
      return "xquery";
    default:
      return "text";
  }
}

const icons = {
  html: <HTMLIcon />,
  css: <CSSIcon />,
  img: <IMGIcon />,
  js: <JSIcon />,
  txt: <TXTIcon />,
  xml: <XML />,
  md: <MDIcon />,
};
export function getIconByFileExtension(extension) {
  return (
    <StyledIcon>{icons[extension] ? icons[extension] : <TXTIcon />}</StyledIcon>
  );
  // return getFileTypeIcon(extension);
}

const StyledIcon = styled.div`
  > svg {
    padding: 4px;
    width: 25px;
    height: 25px;
  }
`;
