import React, { useEffect } from "react";
import ActionCableContainer from "./ActionCableContainer";
import styled from "styled-components";
import { format } from "date-fns";

const Console = ({ selectedWidgetId, hidden }) => {
  const wrapperRef = React.useRef(null);
  const [messages, setMessages] = React.useState([]);

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight;
    }
  }, [messages.length]);

  useEffect(() => {
    if (selectedWidgetId) {
      setMessages(["Here you can see the log..."]);
    }
  }, [selectedWidgetId]);

  const onReceived = (message) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      format(new Date(), "HH:mm:ss:SS") + " " + message,
    ]);
  };
  return (
    <Wrapper ref={wrapperRef} hidden={hidden}>
      <ActionCableContainer
        channel="WidgetChannel"
        selectedWidget={selectedWidgetId}
        onReceived={onReceived}
      />
      {messages?.length > 0 &&
        messages.map((message, index) => (
          <Message key={index}>{message}</Message>
        ))}
    </Wrapper>
  );
};

const Message = styled.div`
  font-family: "Courier Prime", monospace;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
`;

const Wrapper = styled.div`
  border-top: 1px solid #d9d9d9;
  padding: 12px;
  min-height: 124px;
  max-height: 124px;
  overflow-y: auto;
  //margin-bottom: 12px;
  background-color: #ffffff;
  color: #0d0d0d;
  height: ${({ hidden }) => (hidden ? "0px" : "124px")};
`;

export default Console;
