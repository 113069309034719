import React, { useEffect } from "react";
import { Button, Modal, Select } from "antd";
import styled from "styled-components";

const SettingsModal = ({ handleSave, open, onClose, assistants, settings }) => {
  const [selectedAssistant, setSelectedAssistant] = React.useState(null);

  useEffect(() => {
    if (settings.assistantId) {
      setSelectedAssistant(settings.assistantId);
    }
  }, [settings]);

  const onSave = () => {
    handleSave({
      selectedAssistant,
    });
  };

  return (
    <Modal
      onCancel={onClose}
      title={"Settings"}
      open={open}
      centered
      footer={null}
      width={450}
    >
      <h5>Settings</h5>
      <Wrapper>
        <FieldWrapper>
          <label>Assistant</label>
          <Select
            defaultValue={""}
            style={{ width: "100%" }}
            onChange={(value) => setSelectedAssistant(value)}
            value={selectedAssistant}
          >
            {assistants?.map((assistant) => (
              <Select.Option value={assistant.id}>
                {assistant.name}
              </Select.Option>
            ))}
          </Select>
        </FieldWrapper>
      </Wrapper>
      <Buttons>
        <Button type={"primary"} onClick={onSave}>
          Save
        </Button>
      </Buttons>
    </Modal>
  );
};

const FieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 10px;
  align-items: center;
  white-space: nowrap;
`;

const Wrapper = styled.div`
  padding: 20px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default SettingsModal;
