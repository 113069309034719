import axios from "axios";

export const sendMessage = async ({ message, widgetId, assistantId }) => {
  try {
    return await axios.post(`/widgets/${widgetId}/chat`, {
      message,
      assistant_id: assistantId,
    });
  } catch (error) {
    console.error(error);
  }
};
