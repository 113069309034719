import axios from "./axios";

export const login = async (data) => {
  try {
    const response = await axios.post("/login", data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const register = async (data) => {
  try {
    const response = await axios.post("/signup", { ...data, name: "" });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
