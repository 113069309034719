import React from "react";
import CreateWidget from "./CreateWidget";
import ProfileActions from "./ProfileActions";
import styled from "styled-components";
import AgentsSelector from "./AgentsSelector";

const Header = ({
  onEditWidgetName,
  selectedWidgetId,
  widgets = [],
  onAddWidget,
  onSelectWidget,
  onRemoveWidget,
  onOpenSetting,
  selectedAgents,
  onAgentsSelect = () => {},
  isLoadingWidget,
}) => {
  return (
    <ChatHeader>
      <LeftSide>
        <WidgetSelectorWrapper>
          Widget
          <CreateWidget
            onEditWidget={onEditWidgetName}
            currentWidgetId={selectedWidgetId}
            widgets={widgets}
            onAddWidget={onAddWidget}
            onSelectWidget={onSelectWidget}
            onRemoveWidget={onRemoveWidget}
          />
        </WidgetSelectorWrapper>
        <WidgetSelectorWrapper>
          Agents
          <AgentsSelector
            selectedAgents={selectedAgents}
            onAgentsSelect={onAgentsSelect}
            disabled={isLoadingWidget}
          />
        </WidgetSelectorWrapper>
      </LeftSide>
      <ProfileActions onOpenSettings={() => onOpenSetting()} />
    </ChatHeader>
  );
};

const WidgetSelectorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ChatHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LeftSide = styled.div`
  display: flex;
  gap: 20px;
`;

export default Header;
