import React from "react";
import { Button, Dropdown } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const ProfileActions = ({ onOpenSettings }) => {
  const navigate = useNavigate();
  const items = [
    {
      key: "2",
      label: "Settings",
      onClick: () => {
        onOpenSettings();
      },
    },
    {
      key: "1",
      label: "Sign out",
      onClick: () => {
        localStorage.removeItem("token");
        navigate("/login");
      },
    },
  ];
  return (
    <Dropdown menu={{ items }} arrow>
      <Button icon={<UserOutlined />} />
    </Dropdown>
  );
};

export default ProfileActions;
