import React from "react";
import styled from "styled-components";
const { REACT_APP_BASE_URL } = process.env;
const HtmlViewer = ({ widgetId }) => {
  if (!widgetId) return null;
  return (
    <IFrame src={`https://${REACT_APP_BASE_URL}/render/widgets/${widgetId}`} />
  );
};

const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

export default HtmlViewer;
