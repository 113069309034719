import React from "react";
import { Button, Tooltip } from "antd";

const SettingsButton = ({ icon, disabled, onClick, active, title }) => {
  return (
    <Tooltip title={title} onClick={onClick} placement={"bottomLeft"}>
      <Button
        icon={icon}
        disabled={disabled}
        type={active ? "primary" : "default"}
      />
    </Tooltip>
  );
};

export default SettingsButton;
