import React, { useState, useEffect } from "react";
import { Input } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const WidgetOptionComponent = ({ option, onSave, onRemove }) => {
  const [value, setValue] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mouseAction, setMouseAction] = useState(null);

  useEffect(() => {
    setValue(option.label || ""); // Initialize the value when the option changes
  }, [option]);

  const handleEditClick = (e) => {
    e.stopPropagation();
    setIsEditing(true); // Enable editing mode
    setEditValue(value); // Initialize editValue with the current value
  };

  const handleRemoveClick = (e) => {
    e.stopPropagation();
    onRemove(option);
  };

  const handleInputChange = (e) => {
    e.stopPropagation();
    const inputValue = e.target.value;
    setEditValue(inputValue); // Update editValue with the typed input
  };

  const handleSaveClick = async (e) => {
    e?.stopPropagation();
    setIsLoading(true);
    const res = await onSave(option.value, editValue);
    setIsLoading(false);
    if (res) {
      setValue(res.name);
      setIsEditing(false); // Disable editing mode
    }
    // setValue(editValue); // Save the edited value
    // setIsEditing(false); // Disable editing mode
  };

  const handleInputBlur = () => {
    // Handle blur (optional, you can remove this if not needed)
    if (isEditing) {
      handleSaveClick(); // Save the edited value on blur if still editing
    }
  };

  return (
    <div
      style={{
        display: "flex",
        // height: "35px",
        width: "100% !important",
        justifyContent: "space-between",
      }}
      onMouseEnter={() => setMouseAction(true)}
      onMouseLeave={() => setMouseAction(false)}
    >
      {isEditing ? (
        <Input
          style={{ width: "100%" }}
          autoFocus
          loading={isLoading}
          onClick={(e) => e.stopPropagation()}
          value={editValue || ""}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onPressEnter={handleSaveClick}
        />
      ) : (
        value
      )}
      {!isEditing && mouseAction && (
        <div style={{ display: "flex", gap: "10px" }}>
          <EditOutlined onClick={handleEditClick} />
          <DeleteOutlined onClick={handleRemoveClick} />
        </div>
      )}
    </div>
  );
};

export default WidgetOptionComponent;
