import React, { useState, useRef } from "react";
import { Divider, Input, Select, Space, Button, Modal } from "antd";
import WidgetOptionComponent from "./WidgetOptionComponent";
const CreateWidget = ({
  widgets,
  onAddWidget,
  onSelectWidget,
  currentWidgetId,
  onEditWidget,
  onRemoveWidget,
}) => {
  const [name, setName] = useState("");
  const inputRef = useRef(null);
  const [isRemoveModal, setIsRemoveModal] = useState(false);
  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const addItem = (e) => {
    e.preventDefault();
    if (name?.trim()?.length > 0) {
      onAddWidget(name?.trim()).then(() => {
        setName("");
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      });
    }
  };

  const onRemoveWidgetClick = (option) => {
    setIsRemoveModal(option);
  };

  const onRemoveConfirm = () => {
    onRemoveWidget(isRemoveModal).then(() => setIsRemoveModal(false));
  };

  return (
    <>
      {isRemoveModal && (
        <Modal
          okText={"Yes"}
          cancelText={"No"}
          title={`Confirmation`}
          centered
          onClick={(e) => e.stopPropagation()}
          open={true}
          onOk={onRemoveConfirm}
          onCancel={() => setIsRemoveModal(false)}
        >
          <p>Are you sure you want to remove {isRemoveModal?.label} widget?</p>
        </Modal>
      )}
      <Select
        style={{
          width: 300,
        }}
        onSelect={onSelectWidget}
        optionRender={(option) => (
          <WidgetOptionComponent
            option={option}
            onSave={onEditWidget}
            onRemove={onRemoveWidgetClick}
          />
        )}
        value={currentWidgetId}
        placeholder="Select widget"
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider
              style={{
                margin: "8px 0",
              }}
            />
            <Space
              style={{
                padding: "0 8px 4px",
              }}
            >
              <Input
                placeholder="New widget name"
                ref={inputRef}
                value={name}
                onChange={onNameChange}
                onPressEnter={(e) => addItem(e)}
                onKeyDown={(e) => e.stopPropagation()}
              />
              <Button
                disabled={!name?.trim()?.length}
                type="text"
                onClick={addItem}
              >
                Create
              </Button>
            </Space>
          </>
        )}
        options={widgets.map((item) => ({
          label: item.name,
          value: item.id,
        }))}
      />
    </>
  );
};

export default CreateWidget;
