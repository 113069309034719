import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button, Input } from "antd";
import styled from "styled-components";

const SignUpSchema = Yup.object().shape({
  email: Yup.string().required("This field is required"),
  password: Yup.string().required("This field is required"),
});

export const LoginForm = ({ onSubmit, mode }) => {
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={SignUpSchema}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        setSubmitting(true);
        onSubmit(values)
          ?.then(() => {})
          .catch((err) => {
            setFieldError("password", err.response.data.error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ values, handleChange, isSubmitting }) => (
        <Form>
          <FieldWrapper>
            <Input
              value={values.email}
              disabled={isSubmitting}
              type="email"
              size={"large"}
              placeholder="Email"
              onChange={(e) => {
                handleChange({
                  target: { name: "email", value: e.target.value },
                });
              }}
            />
            <ErrorMessage
              name="email"
              style={{ color: "red" }}
              component="div"
              className="error-message"
            />
          </FieldWrapper>
          <FieldWrapper>
            <Input.Password
              size={"large"}
              value={values.password}
              disabled={isSubmitting}
              type="password"
              placeholder="Password"
              onChange={(e) => {
                handleChange({
                  target: { name: "password", value: e.target.value },
                });
              }}
            />
            <ErrorMessage
              name="password"
              component="div"
              style={{ color: "red" }}
              className="error-message"
            />
          </FieldWrapper>
          <StyledButton type="primary" htmlType="submit">
            {mode === "login" ? "Sign in" : "Sign up"}
          </StyledButton>
        </Form>
      )}
    </Formik>
  );
};

const FieldWrapper = styled.div`
  width: 300px;
  margin-bottom: 19px;
  height: 40px;
`;

const StyledButton = styled(Button)`
  //margin-top: 5px;
  width: 300px;
`;
