import {
  ActionCableConsumer,
  ActionCableProvider,
} from "@thrash-industries/react-actioncable-provider";
const { REACT_APP_BASE_URL } = process.env;
const ActionCableContainer = ({ channel, onReceived, selectedWidget }) => {
  if (!selectedWidget) return null;
  const url = `wss://${REACT_APP_BASE_URL}/cable`;
  return (
    <ActionCableProvider url={url}>
      <ActionCableConsumer
        channel={{ channel, widget_id: selectedWidget }}
        onReceived={onReceived}
      />
    </ActionCableProvider>
  );
};

export default ActionCableContainer;
