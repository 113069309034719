import React from "react";
import axios from "axios";

const UseAssistants = () => {
  const [assistants, setAssistants] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const getAssistants = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/assistants");
      setAssistants(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  React.useEffect(() => {
    getAssistants();
  }, []);

  return {
    data: assistants,
    isLoading,
    error,
  };
};

export default UseAssistants;
