import { createBrowserRouter } from "react-router-dom";
import AuthGuard from "./components/AuthGuard";
import Auth from "./pages/Auth/Auth";
import SharedWidget from "./pages/SharedWidget/SharedWidget";
import React from "react";
import App from "./App";

export const routerPath = {
  ROOT: "/",
  LOGIN: "/login",
  SHARE: "/share/:id",
};

const router = createBrowserRouter([
  {
    path: routerPath.ROOT,
    element: <AuthGuard />,
    children: [
      {
        path: "/",
        element: <App />,
      },
    ],
  },
  {
    path: routerPath.LOGIN,
    element: <Auth />,
  },
  {
    path: routerPath.SHARE,
    element: <SharedWidget />,
  },
]);

export default router;
