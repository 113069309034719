import React, { useEffect } from "react";
import { CopyBlock, github } from "react-code-blocks";
import styled from "styled-components";
import FilesStructure from "./FilesStructure";
import axios from "../services/axios";
import { getFileType } from "../utils";
import Markdown from "react-markdown";

const CodeWrapper = styled.div`
  animation: show 1s;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  > div {
    border-radius: 0;
  }
`;

const CodeViewer = ({ files }) => {
  const [file, setFile] = React.useState({
    content: "",
    extension: "",
  });

  useEffect(() => {
    setFile({
      content: "",
      extension: "",
    });
  }, [files]);

  const onSelect = (_, { node }) => {
    if (node.isLeaf) {
      axios
        .get(`/files/${node.key}`)
        .then((response) => {
          setFile(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  return (
    <CodeWrapper>
      <CodeWithFilesWrapper>
        <FilesStructure files={files} onSelectFile={onSelect}></FilesStructure>
        {file && file.content && file.extension !== "md" && (
          <CopyBlock
            showLineNumbers
            text={file.content}
            language={getFileType(file.extension)}
            customStyle={{
              padding: "12px",
              width: "100%",
              height: "100%",
              overflow: "auto",
            }}
            theme={github}
          />
        )}
        {file && file.content && file.extension === "md" && (
          <Wrapper>
            <Markdown>{file.content}</Markdown>
          </Wrapper>
        )}
      </CodeWithFilesWrapper>
    </CodeWrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  padding: 20px;
`;

const CodeWithFilesWrapper = styled.div`
  display: flex;
  height: 100%;
`;

export default CodeViewer;
