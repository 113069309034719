import { useEffect, useState } from "react";
import { getAgents } from "../services/widgetService";

const UseGetAgents = () => {
  const [agents, setAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAgents = async () => {
      setIsLoading(true);
      try {
        const response = await getAgents();
        setAgents(response);
        setError(null);
      } catch (error) {
        setError(error);
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAgents();
  }, []);

  return {
    data: agents,
    isLoading,
    error,
  };
};

export default UseGetAgents;
