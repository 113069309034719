import React from "react";
import styled from "styled-components";

const MainLayout = ({ left, right, children, header }) => {
  return (
    <Wrapper>
      <Header>{header}</Header>
      <ContentWrapper>
        <ChatSide>{left}</ChatSide>
        <RenderSide>{right}</RenderSide>
      </ContentWrapper>
      {children}
    </Wrapper>
  );
};

const ContentWrapper = styled.div`
  width: 100%;
  height: calc(100% - 64px);
  display: flex;
`;
const Header = styled.div`
  width: 100%;
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  border-bottom: 1px solid #d9d9d9;
  padding: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;
const ChatSide = styled.div`
  min-width: 496px;
  max-width: 496px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  border-right: 1px solid #d9d9d9;
  height: 100%;
`;

const RenderSide = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export default MainLayout;
