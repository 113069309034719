import React from "react";
import styled from "styled-components";
import Markdown from "react-markdown";

const HistoryMessage = ({ type, text }) => {
  return (
    <Wrapper>
      <Author>{type === "user" ? "You" : "GPT"}</Author>
      <Message>
        <Markdown>{text}</Markdown>
      </Message>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
`;
const Message = styled.div`
  font-size: 16px;
  line-height: 24px;
  width: Fill (428px) px;
  height: Hug (100px) px;
  padding: 8px 16px 8px 16px;
  gap: 8px;
  border-radius: 8px;
  background-color: #f5f5f580;
`;

const Author = styled.div`
  font-weight: 600;
  line-height: 24px;
`;

export default HistoryMessage;
