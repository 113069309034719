import React, { useEffect } from "react";
import { Select } from "antd";
import useGetAgents from "../hooks/useGetAgents";

const AgentsSelector = ({ selectedAgents, onAgentsSelect, disabled }) => {
  const { isLoading, data: agents, error } = useGetAgents();
  const [value, setValue] = React.useState(selectedAgents || []);

  const handleChange = (value) => {
    setValue(value);
  };

  useEffect(() => {
    handleChange(selectedAgents);
  }, [selectedAgents]);

  const handleBlur = () => {
    onAgentsSelect(value);
  };

  const options = agents?.map((agent) => ({
    label: agent.name,
    value: agent.id,
  }));

  return (
    <Select
      loading={isLoading}
      disabled={isLoading || disabled}
      defaultValue={selectedAgents}
      onBlur={handleBlur}
      value={value}
      status={error ? "error" : ""}
      onChange={handleChange}
      style={{
        width: 500,
      }}
      filterOption={(value, option) => {
        return option.label.toLowerCase().includes(value.toLowerCase());
      }}
      maxTagCount={2}
      maxTagTextLength={25}
      options={options}
      mode={"multiple"}
      placeholder="Select Agents"
    />
  );
};

export default AgentsSelector;
