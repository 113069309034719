import { useEffect, useState } from "react";
import { sendMessage } from "../services/gptService";
import {
  createWidget,
  deleteWidget,
  getWidget,
  getWidgets,
  setWidgetAgents,
  updateWidget,
} from "../services/widgetService";

const useGPT = ({ settings }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [widgets, setWidgets] = useState([]);
  const [selectedWidgetId, setSelectedWidgetId] = useState(null);
  const [files, setFiles] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);

  const fetchWidgets = async () => {
    setIsLoading(true);
    try {
      const response = await getWidgets();
      setWidgets(response);
      if (response.length > 0) {
        const selectedWidgetId = localStorage.getItem("selectedWidgetId");
        await onSelectWidget(selectedWidgetId || response[0].id);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSelectWidget = async (id) => {
    setSelectedWidgetId(id);
    try {
      setIsLoading(true);
      const response = await getWidget(id);
      localStorage.setItem("selectedWidgetId", id);
      setMessages(response.messages);
      setFiles(response.file_hierarchy);
      setSelectedAgents(response.agents);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSelectAgents = async (agents) => {
    await setWidgetAgents(selectedWidgetId, agents);
  };

  const onEditWidgetName = (id, name) => {
    return updateWidget(id, { name }).then((res) => {
      if (res) {
        setWidgets(widgets.map((widget) => (widget.id === id ? res : widget)));
      }
      return res;
    });
  };
  const onRemoveWidget = ({ value }) => {
    return deleteWidget(value).then((res) => {
      if (res) {
        const newWidgets = widgets.filter((widget) => widget.id !== value);
        setWidgets(newWidgets);
        if (selectedWidgetId === value && newWidgets[0]?.id) {
          onSelectWidget(newWidgets[0]?.id);
        } else if (selectedWidgetId === value) {
          setSelectedWidgetId(null);
          setMessages([]);
          setFiles([]);
          setSelectedAgents([]);
          localStorage.removeItem("selectedWidgetId");
        }
      }
      return res;
    });
  };

  const onAddWidget = async (name) => {
    try {
      const response = await createWidget(name);
      const { id } = response;

      setWidgets([...widgets, response]);
      await onSelectWidget(id);
      setMessages([]);
    } catch (error) {
      console.error(error);
    }
  };

  const getRes = async (message) => {
    setIsLoading(true);
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        content: message,
        role: "user",
      },
    ]);
    try {
      await sendMessage({
        message,
        widgetId: selectedWidgetId,
        assistantId: settings.assistantId,
      });
      await onSelectWidget(selectedWidgetId);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchWidgets();
  }, []);

  return {
    isLoading,
    messages,
    widgets,
    selectedWidgetId,
    onSelectWidget,
    onEditWidgetName,
    onAddWidget,
    onRemoveWidget,
    onSendMessage: getRes,
    onSelectAgents,
    files,
    selectedAgents,
  };
};

export default useGPT;
