import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LoginForm } from "../../components/LoginForm";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { login, register } from "../../services/authService";
import { DownCircleOutlined } from "@ant-design/icons";

const Auth = () => {
  const [mode, setMode] = React.useState("login");
  const navigate = useNavigate();

  const toggleMode = () => {
    setMode(mode === "login" ? "register" : "login");
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  const handleLogin = async (values) => {
    if (mode === "login") {
      const res = await login(values);
      if (res) {
        localStorage.setItem("token", res.access_token);
        navigate("/");
      }
      return res;
    } else {
      try {
        const res = await register(values);
        if (res) {
          setMode("success_register");
        }
        return res;
      } catch (error) {
        throw error;
        console.log(error);
      }
    }
  };

  return (
    <Container>
      <Wrapper>
        {(mode === "login" || mode === "register") && (
          <>
            <StyledLogo />
            <ModeLabel>
              {mode === "login"
                ? "Sign in to your account"
                : "Create your account"}
            </ModeLabel>
            <LoginForm mode={mode} onSubmit={handleLogin} />
            <span>
              <SwitchWrapper>
                <div>
                  {mode === "login"
                    ? "Don't have an account?"
                    : "Already have an account?"}
                </div>
                <SwitchButton onClick={toggleMode}>
                  {mode === "login" ? "Sign up" : "Sign in"}
                </SwitchButton>
              </SwitchWrapper>
            </span>
          </>
        )}
        {mode === "success_register" && (
          <SuccessWrapper>
            <DownCircleOutlined style={{ fontSize: "60px" }} />
            Register success.{" "}
            <SwitchButton onClick={() => setMode("login")}>
              Go to Login
            </SwitchButton>
          </SuccessWrapper>
        )}
      </Wrapper>
    </Container>
  );
};

const SuccessWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  height: 100%;
  flex-direction: column;
  gap: 10px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #e6f7ff;
`;

const StyledLogo = styled(Logo)`
  margin-bottom: 24px;
`;

const Wrapper = styled.div`
  padding: 60px 40px;
  width: 380px;
  height: 441px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 #00000026;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SwitchWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 24px;
`;

const SwitchButton = styled.div`
  cursor: pointer;
  font-family: Roboto, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;

  color: #1890ff;
  &:hover {
    text-decoration: underline;
  }
`;

const ModeLabel = styled.div`
  font-family: Roboto, serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: #1890ff;
  margin-bottom: 40px;
`;

export default Auth;
