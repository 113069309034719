import React from "react";
import { Button, Modal } from "antd";
import { handleCopyToClipboard } from "../utils";
import { LinkOutlined } from "@ant-design/icons";
import styled from "styled-components";

const ShareModal = ({ widgetId, onClose }) => {
  const [copied, setCopied] = React.useState(false);
  const onCopy = () => {
    setCopied(
      handleCopyToClipboard(`${window.location.href}share/${widgetId}`),
    );
  };

  return (
    <Modal
      onCancel={onClose}
      centered
      title={"Do you want to share?"}
      footer={null}
      width={400}
      open={true}
    >
      <p>Copy link to share a project</p>
      <ButtonsWrapper>
        <Button type="primary" onClick={() => onCopy()}>
          {copied ? (
            <CopyWrapper>
              <LinkOutlined />
              Copied
            </CopyWrapper>
          ) : (
            <CopyWrapper>
              <LinkOutlined />
              Copy
            </CopyWrapper>
          )}
        </Button>
      </ButtonsWrapper>
    </Modal>
  );
};

const CopyWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default ShareModal;
