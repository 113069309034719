import React from "react";
import styled from "styled-components";
import { Tree } from "antd";
import { convertToTreeData } from "../utils";

const { DirectoryTree } = Tree;

const FilesStructure = ({ files, onSelectFile }) => {
  const [treeData, setTreeData] = React.useState([]);
  React.useEffect(() => {
    if (files) {
      setTreeData(convertToTreeData(files));
    }
  }, [files]);

  return (
    <Wrapper>
      {!Object.keys(treeData).length > 0 &&
        "No directories or files to display"}
      {Object.keys(treeData).length > 0 && (
        <DirectoryTree
          treeData={treeData}
          defaultExpandAll
          onSelect={onSelectFile}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 4px;
  max-width: 360px;
  min-width: 360px;
  overflow: auto;
  width: 100%;
  //background-color: #f5f5f5;
  border-right: 1px solid #d9d9d9;
`;

export default FilesStructure;
