import React, { useRef } from "react";
import HistoryMessage from "./HistoryMessage";
import styled from "styled-components";

const MessagesList = ({ messages }) => {
  const ref = useRef();

  const scrollToBottom = () => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [messages]);
  return (
    <Wrapper ref={ref}>
      {messages?.length > 0 &&
        messages?.map((item, index) => (
          <HistoryMessage key={index} text={item.content} type={item.role} />
        ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 10px 0;
  gap: 16px;
`;

export default MessagesList;
