import React from "react";
import { Input } from "antd";
import styled from "styled-components";
const { TextArea } = Input;
const GptInput = ({ value, disabled, isWidgetSelected, onChange, onSend }) => {
  return (
    <InputWrapper>
      <TextArea
        value={value}
        disabled={disabled}
        autoSize
        type="text"
        size="large"
        placeholder={
          !isWidgetSelected
            ? "Please, select widget first"
            : "Message ChatGPT..."
        }
        onPressEnter={(e) => {
          if (!e.shiftKey) {
            onSend();
          }
        }}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export default GptInput;
