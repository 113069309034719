import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Spin, Switch } from "antd";
import {
  ArrowLeftOutlined,
  CodeOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import useGPT from "../../hooks/useGPT";
import ShareModal from "../../components/ShareModal";
import SettingsModal from "../../components/SettingsModal";
import MessagesList from "../../components/MessagesList";
import GPTInput from "../../components/GPTInput";
import HTMLViewer from "../../components/HTMLViewer";
import CodeViewer from "../../components/CodeViewer";
import MainLayout from "../../layout/MainLayout";
import Header from "../../components/Header";
import UseAssistants from "../../hooks/useAssistants";
import Console from "../../components/Console";
import SettingsButton from "../../components/SettingsButton";

function App() {
  const [input, setInput] = useState("");
  const [showCode, setShowCode] = useState(false);
  const [showConsole, setShowConsole] = useState(true);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [settings, setSettings] = useState({
    assistantId: null,
  });
  const { data: assistants } = UseAssistants();
  const {
    isLoading,
    messages,
    selectedWidgetId,
    widgets,
    onSelectWidget,
    onEditWidgetName,
    onRemoveWidget,
    onAddWidget,
    onSendMessage,
    onSelectAgents,
    files,
    selectedAgents,
  } = useGPT({ settings });

  const onSave = ({ selectedAssistant }) => {
    setSettings((prev) => {
      localStorage.setItem(
        "settings",
        JSON.stringify({
          ...prev,
          assistantId: selectedAssistant,
        }),
      );
      return {
        ...prev,
        assistantId: selectedAssistant,
      };
    });
    setIsSettingsOpen(false);
  };

  const sendMessageToGPT = async () => {
    await onSendMessage(input);
    setInput("");
  };

  const handleRevertLastChanges = async () => {
    await onSendMessage("revert last changes");
  };

  const toggleCode = () => {
    setShowCode(!showCode);
  };

  useEffect(() => {
    if (localStorage.getItem("settings")) {
      const settings = JSON.parse(localStorage.getItem("settings"));
      setSettings(settings);
    }
  }, []);

  return (
    <>
      <MainLayout
        header={
          <Header
            isLoadingWidget={isLoading}
            onRemoveWidget={onRemoveWidget}
            onSelectWidget={onSelectWidget}
            onAddWidget={onAddWidget}
            widgets={widgets}
            onEditWidgetName={onEditWidgetName}
            selectedWidgetId={selectedWidgetId}
            onOpenSetting={() => setIsSettingsOpen(true)}
            selectedAgents={selectedAgents}
            onAgentsSelect={onSelectAgents}
          />
        }
        left={
          <>
            <MessagesList messages={messages} />
            <GPTInput
              disabled={isLoading || !selectedWidgetId}
              isWidgetSelected={selectedWidgetId}
              value={input}
              onSend={sendMessageToGPT}
              onChange={setInput}
            />
          </>
        }
        right={
          <RightWrapper>
            <HeaderWrapper>
              <SwitchMode>
                Code
                <Switch checked={showCode} onChange={toggleCode} />
              </SwitchMode>
              <ToolbarWrapper>
                <SettingsButton
                  title="Revert last changes"
                  disabled={!selectedWidgetId || isLoading}
                  icon={<ArrowLeftOutlined />}
                  onClick={handleRevertLastChanges}
                />
                <SettingsButton
                  title="Show console"
                  disabled={false}
                  icon={<CodeOutlined />}
                  active={showConsole}
                  onClick={() => {
                    if (selectedWidgetId) setShowConsole(!showConsole);
                  }}
                />
                <SettingsButton
                  title={"Share"}
                  active={isShareModalOpen}
                  icon={<ShareAltOutlined />}
                  onClick={() => setIsShareModalOpen(true)}
                />
              </ToolbarWrapper>
            </HeaderWrapper>
            <IframeWithConsoleWrapper>
              {!isLoading && selectedWidgetId && !showCode && (
                <HTMLViewer widgetId={selectedWidgetId} />
              )}
              {showCode && !isLoading && <CodeViewer files={files} />}
              {isLoading && selectedWidgetId && (
                <LoadingWrapper>
                  <Spin />
                </LoadingWrapper>
              )}
              <Console
                selectedWidgetId={selectedWidgetId}
                hidden={!showConsole}
              />
            </IframeWithConsoleWrapper>
          </RightWrapper>
        }
      >
        {isShareModalOpen && (
          <ShareModal
            widgetId={selectedWidgetId}
            isOpen={isShareModalOpen}
            onClose={() => setIsShareModalOpen(false)}
          />
        )}
        {isSettingsOpen && (
          <SettingsModal
            assistants={assistants}
            open={isSettingsOpen}
            onClose={() => setIsSettingsOpen(false)}
            handleSave={onSave}
            settings={settings}
          />
        )}
      </MainLayout>
    </>
  );
}

const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const HeaderWrapper = styled.div`
  height: 60px;
  padding: 16px 14px;
  background-color: #f5f5f5;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
`;

const ToolbarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
`;

const SwitchMode = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

const EmptyWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IframeWithConsoleWrapper = styled.div`
  width: 100%;
  height: calc(100% - 60px); // 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export default App;
