import axios from "axios";
const { REACT_APP_BASE_URL } = process.env;
console.log(process.env);
axios.defaults.baseURL = `https://${REACT_APP_BASE_URL}`;

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

export default axios;
